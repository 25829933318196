import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBBNlFc9CtllHoa9Yaqglj77qdmVpf1IUA",
    authDomain: "twitter-clone-e0f6f.firebaseapp.com",
    projectId: "twitter-clone-e0f6f",
    storageBucket: "twitter-clone-e0f6f.appspot.com",
    messagingSenderId: "1009667747375",
    appId: "1:1009667747375:web:a8956854da95fdff1de0da",
    measurementId: "G-EYQWFJR62N"
  };

 const firebaseApp = firebase.initializeApp(firebaseConfig);

 const db = firebaseApp.firestore();
 
 export default db;