import React, { useState } from "react";
import "./TweetBox.css";
import { Avatar, Button } from "@material-ui/core";
import db from "./firebase";

function TweetBox() {
  const [tweetMessage, setTweetMessage] = useState("");
  const [tweetImage, setTweetImage] = useState("");
  const sendTweet = (e) => {
    e.preventDefault();
    db.collection("posts").add({
      displayName: "Wayne Greagori",
      userName: "greagori",
      verified: true,
      text: tweetMessage,
      image: tweetImage,
      avatar:
        "https://media-exp1.licdn.com/dms/image/C4E03AQHc54OJnAeAbw/profile-displayphoto-shrink_200_200/0/1560167785574?e=1654732800&v=beta&t=i1wew5WTaqrsr9xBdlfsHSn4T2Jnt7vmi19DlcA0D-Y",
    });
  };

  return (
    <div className="tweetBox">
      <form>
        <div className="tweetBox__input">
          <Avatar src="https://media-exp1.licdn.com/dms/image/C4E03AQHc54OJnAeAbw/profile-displayphoto-shrink_400_400/0/1560167785574?e=1654128000&v=beta&t=GAfetYCRZpGVB1PIzZA5p3MV253UJ_8nCnRxqp8LyZI" />
          <input
            onChange={(e) => setTweetMessage(e.target.value)}
            value={tweetMessage}
            placeholder="What's happening?"
            type="text"
          />
        </div>
        <input
          onChange={(e) => setTweetImage(e.target.value)}
          value={tweetImage}
          className="tweetBox__imageInput"
          placeholder="Optional: Enter image URL"
          type="text"
        />

        <Button
          className="tweetBox__tweetButton"
          onClick={sendTweet}
          type="submit"
        >
          Tweet
        </Button>
      </form>
    </div>
  );
}

export default TweetBox;
